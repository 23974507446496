import { container } from 'tsyringe'
import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  EventBus,
  EventBusConstants,
  REQUEST_TOP_SALDO_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { RequestTopupSaldoPresenter } from '@/app/ui/presenters/RequestTopupSaldoPresenter'
import { Pagination } from '@/domain/entities/Pagination'
import {
  dataHistoryUploadBulky,
  DetailTopupSaldo,
  DetailTopupSaldoForm,
  HistoryUploadBulky,
  RequestTopupSaldoData,
  RequestTopupSaldoHistory,
  RequestTopupSaldoList,
  UploadBulky,
} from '@/domain/entities/RequestTopupSaldo'
import {
  ApproveRejectBulkyRequest,
  ApprovingTopupRequest,
  RequestTopupRequest,
} from '@/data/payload/api/AdjustmentRequest'
import { EnumTypeBalanceStatus } from '@/app/infrastructures/misc/Constants/adjustment'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import { UploadRequest } from '@/data/payload/api/UploadRequest'
import Axios, { CancelTokenSource } from 'axios'

export interface RequestTopupListParams {
  page: number
  perPage: number
  balanceStatus?: string
  filterStatus?: string
  phoneNumber?: string
  balanceType?: string
  sortBy?: string
  customerId?: number
  startDate?: string
  endDate?: string
  search?: string
  status?: string
  tab?: string
}

export interface RequestTopupSaldoState {
  isLoading: boolean
  listRequestTopup: RequestTopupSaldoData[] | null
  paginationData: Pagination
}

export interface RequestTopupFormState {
  customerId: number
  balanceTypeAdjustment: string
  amount: number
  note: string
}

export interface ApprovingTopupFormState {
  balanceId: number
  balanceStatus: EnumTypeBalanceStatus
  reasonNote: string
}

export interface ApproveRejectBulkyFormState {
  balanceStatus: EnumTypeBalanceStatus
  balanceIDs: Array<number>
  reasonNote?: string
}

@Module({
  namespaced: true,
  store,
  name: 'adjustment-request-topup',
  dynamic: true,
})
class RequestTopupSaldoController extends VuexModule
  implements RequestTopupSaldoState {
  private presenter: RequestTopupSaldoPresenter = container.resolve(
    RequestTopupSaldoPresenter
  )
  public isLoading = false
  public listRequestTopup: RequestTopupSaldoData[] | null = null
  public listRequestTopupHistory = [new RequestTopupSaldoHistory()]
  public paginationData = new Pagination(1, REQUEST_TOP_SALDO_PAGINATION, 0)
  public detailTopupForm = new DetailTopupSaldo()
  public phoneNumber = ''
  public statusUploadBulky = EnumStatusUpload.START
  public errUploadBulky: null | string = null
  public responseUploadBulky: UploadBulky | null = null
  public statusSuccessProcessBulkyRequestTopUp = ''
  public forceStart = false
  public cancelToken: CancelTokenSource | undefined = undefined
  public statusApproveRejectBulky = ''
  public dataRetry = NaN
  public dataHistoryUploadBulky: Array<dataHistoryUploadBulky> = []
  public paginationHistoryUploadBulky: Pagination = new Pagination(1, REQUEST_TOP_SALDO_PAGINATION, 0)
  public statusRequestTopupSaldo = ''
  public statusApproveReject = ''

  @Action({ rawError: true })
  public getListRequestTopupSaldo(params: RequestTopupListParams): void {
    this.setLoading(true)
    this.setListRequestTopupNull()

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setListRequestTopup(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Request Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHistoryRequestTop(params: RequestTopupListParams): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistory(formattedParams)
      .then(res => {
        this.setListRequestTopupHistory(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List History Request Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createRequestTopup(form: RequestTopupFormState): void {
    this.setLoading(true)
    this.presenter
      .postRequestTopup(
        new RequestTopupRequest(form.customerId, form.amount, form.note, form.balanceTypeAdjustment)
      )
      .then(() => {
        this.setStatusRequestTopupSaldo(EventBusConstants.CREATE_REQUEST_TOPUP_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetailTopupForm(customerId: number): void {
    this.setLoading(true)

    this.presenter
      .getDetailTopup(customerId)
      .then(res => {
        this.setDetailTopupForm(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public approvingTopupSaldo(form: ApprovingTopupFormState): void {
    this.setLoading(true)
    this.presenter
      .postApprovingTopup(
        new ApprovingTopupRequest(
          form.balanceId,
          form.balanceStatus,
          form.reasonNote
        )
      )
      .then(() => {
        if (form.balanceStatus === EnumTypeBalanceStatus.APPROVED) {
          this.setStatusApproveReject(EventBusConstants.APPROVE_APPROVING_TOPUP_SUCCESS)
        } else {
          this.setStatusApproveReject(EventBusConstants.REJECT_APPROVING_TOPUP_SUCCESS)
        }
      })
      .catch(
        (error: {
          status: number
          error: { message: { en: string | undefined } }
        }) => {
          if (form.balanceStatus === EnumTypeBalanceStatus.APPROVED) {
            this.setStatusApproveReject(EventBusConstants.APPROVE_APPROVING_TOPUP_FAILED)
          } else {
            this.setStatusApproveReject(EventBusConstants.REJECT_APPROVING_TOPUP_FAILED)
          }
          Vue.notify({
            title: `Topup Saldo Failed To ${form.balanceStatus}`,
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      )
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public approveRejectBulky(form: ApproveRejectBulkyFormState): void {
    this.setLoading(true)

    this.presenter
      .postApproveRejectBulk(
        new ApproveRejectBulkyRequest(
          form.balanceStatus,
          form.balanceIDs,
          form.reasonNote,
        )
      )
      .then(() => {
        if (form.balanceStatus === EnumTypeBalanceStatus.APPROVED) {
          this.setStatusApproveRejectBulky(EventBusConstants.APPROVE_SALDO_BULKY_SUCCESS)
        } else {
          this.setStatusApproveRejectBulky(EventBusConstants.REJECT_SALDO_BULKY_SUCCESS)
        }
      })
      .catch((error) => {
        if (form.balanceStatus === EnumTypeBalanceStatus.APPROVED) {
          this.setStatusApproveRejectBulky(EventBusConstants.APPROVE_SALDO_BULKY_FAILED)
        } else {
          this.setStatusApproveRejectBulky(EventBusConstants.REJECT_SALDO_BULKY_FAILED)
        }
        Vue.notify({
          title: `Failed to ${Utils.toCapitalize(form.balanceStatus)} Bulky TopUp Saldo`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulkyRequestTopUp(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .uploadBulkyRequestTopUp(
        new UploadRequest(payload.file),
        this.cancelToken
      )
      .then(() => {
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(null)
      })
      .catch(error => {
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
      })
  }

  @Action({ rawError: true })
  public getPreviewUploadBulky(params: RequestTopupListParams): void {
    this.setRespUploadBulky(null)
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .previewUploadBulky(formattedParams)
      .then(res => {
        this.setRespUploadBulky(res)
      })
      .catch(error => {
        this.setRespUploadBulky(null)
        Vue.notify({
          title: 'Fetch List Preview Upload Bulky Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public processBulkyRequestTopUp(): void {
    this.setLoading(true)

    this.presenter
      .processBulkyRequestTopUp()
      .then(() => {
        this.setStatusSuccessProcessBulkyRequestTopUp(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_SUCCESS)
      })
      .catch(error => {
        if (error.status === 9) {
          this.setDatRetry(Number(error.error.message.id.split(' ')[1]) || 0)
          this.setStatusSuccessProcessBulkyRequestTopUp(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_RETRY)
          return
        }
        if (error.status === 10) {
          this.setDatRetry(Number(error.error.message.id.split(' ')[1]) || 0)
          this.setStatusSuccessProcessBulkyRequestTopUp(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_NOT_RETRY)
          return
        }
        Vue.notify({
          title: 'Send Bulky Upload Request Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelRequest(): void {
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
  }

  @Mutation
  public setStatusApproveRejectBulky(status: string): void {
    this.statusApproveRejectBulky = status
  }

  @Action({ rawError: true})
  public getHistoryUploadBulky(params: RequestTopupListParams): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistoryUploadBulky(formattedParams)
      .then((res: HistoryUploadBulky) => {
        this.setDataHistoryUploadBulky(res)
      })
      .catch((error) => {
        Vue.notify({
          title: 'Fetch List History Upload Bulky TopUp Saldo Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public setStatusUpload(enumStatus: EnumStatusUpload): void {
    this.statusUploadBulky = enumStatus
  }

  @Mutation
  public setForceStart(isForce: boolean): void {
    this.forceStart = isForce
  }

  @Mutation
  private setErrUploadBulky(err: null | string): void {
    this.errUploadBulky = err
  }

  @Mutation
  private setRespUploadBulky(val: UploadBulky | null): void {
    this.responseUploadBulky = val
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setListRequestTopup(res: RequestTopupSaldoList): void {
    this.listRequestTopup = <RequestTopupSaldoData[]>res.data
    this.paginationData = <Pagination>res.pagination
  }

  @Mutation
  private setListRequestTopupNull(): void {
    this.listRequestTopup = null
  }

  @Mutation
  private setListRequestTopupHistory(res: RequestTopupSaldoList): void {
    this.listRequestTopupHistory = <RequestTopupSaldoData[]>res.data
    this.paginationData = <Pagination>res.pagination
    this.phoneNumber = <string>res.phoneNumber
  }

  @Mutation
  private setDetailTopupForm(res: DetailTopupSaldoForm): void {
    this.detailTopupForm = <DetailTopupSaldo>res.data
  }

  @Mutation
  public setDatRetry(count: number): void {
    this.dataRetry = count
  }

  @Mutation
  public setStatusSuccessProcessBulkyRequestTopUp(status: string): void {
    this.statusSuccessProcessBulkyRequestTopUp = status
  }

  @Mutation
  public setDataHistoryUploadBulky(res: HistoryUploadBulky): void {
    this.dataHistoryUploadBulky = res.data
    this.paginationHistoryUploadBulky = res.pagination
  }

  @Mutation
  public setStatusRequestTopupSaldo(status: string): void {
    this.statusRequestTopupSaldo = status
  }

  @Mutation
  public setStatusApproveReject(status: string): void {
    this.statusApproveReject = status
  }
}

export default getModule(RequestTopupSaldoController)
