






































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { maxLength, maxValue, minLength, minValue, required, ValidationRule, } from 'vuelidate/lib/validators'
import {
  EventBusConstants,
  HEADER_REQUEST_TOPUP_HISTORY,
  MODAL_CONFIRM_REQUEST_TOPUP,
  MODAL_SUCCESS_REQUEST_TOPUP,
  REQUEST_TOP_SALDO_PAGINATION,
  Utils
} from '@/app/infrastructures/misc'
import {
  MAX_NOMINAL_REQUEST_TOPUP_SALDO,
  MAX_NOTES_REQUEST_TOPUP
} from '@/app/infrastructures/misc/Constants/validation'
import * as constantsData from '@/app/infrastructures/misc/Constants/adjustment'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import controller, { RequestTopupListParams } from '@/app/ui/controllers/RequestTopupSaldoController'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import ModalConfirm from '../../components/AdjustmentModals/ModalConfirm/index.vue'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import IconInfoCircle from '@/app/ui/assets/icon_info_circle.vue'
import { Dictionary } from 'vue-router/types/router'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'

export interface ValidationsInterface {
  form: {
    amount: {
      required: () => ValidationRule
      minValue: ValidationRule
      maxValue: ((validation: unknown) => boolean)
    }
    note: {
      required: () => ValidationRule,
      minLength: ValidationRule,
      maxLength: ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    RadioButton,
    Button,
    DataTableV2,
    AdjustmentTextInput,
    LoadingOverlay,
    PaginationNav,
    ModalConfirm,
    Modal,
    ModalDetail,
    IconInfoCircle
  }
})
export default class RequestTopupDetailPage extends Vue {
  controller = controller
  isSuperAdmin = Utils.isSuperAdmin()
  constantsData = constantsData

  form = {
    balanceTypeAdjustment: constantsData.EnumTopUpBalanceType.PLUS,
    amount: NaN,
    note: ''
  }
  parameters = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    filterStatus: 'SHOWALL',
    sortBy: 'NEWEST',
    customerId: 0
  }
  showTable = true
  headers = HEADER_REQUEST_TOPUP_HISTORY
  modalConfirm = false
  modalSuccess = false
  modalDetail = false
  currentNotes = ''
  MODAL_CONFIRM_REQUEST_TOPUP = MODAL_CONFIRM_REQUEST_TOPUP
  MODAL_SUCCESS_REQUEST_TOPUP = MODAL_SUCCESS_REQUEST_TOPUP

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: REQUEST_TOP_SALDO_PAGINATION,
        filterStatus: 'SHOWALL',
        sortBy: 'NEWEST',
        customerId: 0
      }
    }
    this.fetchHistory()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(1),
          maxValue: (validation: unknown) => {
            if (
              this.form.balanceTypeAdjustment ===
              constantsData.EnumTopUpBalanceType.PLUS
            ) {
              return maxValue(MAX_NOMINAL_REQUEST_TOPUP_SALDO)(validation)
            }
            return true
          },
        },
        note: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(MAX_NOTES_REQUEST_TOPUP)
        },
      },
    }
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id)
    }
  }

  get phoneNumberUser(): string {
    return this.controller.phoneNumber ? Utils.countryIndonesiaPhoneNumber(this.controller.phoneNumber, true) : '-'
  }

  get dataRequestTopHistoryList(): Array<DataObject[]> {
    return this.controller.listRequestTopupHistory.map(item => {
      return [
        {
          value: this.formatDate(item.createdAt || ''),
          customStyle: {minWidth: '220px', maxWidth: '220px'}
        },
        {
          value: `${this.getBalanceType(
            item.balanceTypeID || ''
          )}${Utils.toRupiah(Math.abs(item.totalSaldoRequest || 0))}`,
          customStyle: { minWidth: '125px', maxWidth: '125px' },
        },
        {
          value: Utils.toRupiah(Number(item.currentBalance)),
          customStyle: {minWidth: '140px', maxWidth: '140px'}
        },
        {
          value: item.requestor,
          customStyle: {minWidth: '140px', maxWidth: '140px'}
        },
        {
          value: item.approval || '-',
          customStyle: {minWidth: '140px', maxWidth: '140px'}
        },
        {
          value: item.note || '-',
          customStyle: {minWidth: '155px', maxWidth: '155px'}
        },
        {
          value: item.balanceStatusId,
          customStyle: {minWidth: '110px', maxWidth: '110px'}
        }
      ]
    })
  }

  private getBalanceType(balanceType: string): string {
    return balanceType.toUpperCase() ===
      constantsData.EnumBalanceID.DEDUCT
      ? '-'
      : '+'
  }

  private formatDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY HH:mm Z')
    )
  }

  private seeNotes(index: number): void {
    this.modalDetail = true
    this.currentNotes = <string>this.dataRequestTopHistoryList[index][5].value
  }

  private onShowHide(): void {
    this.showTable = !this.showTable
  }

  private fetchHistory(): void {
    this.controller.getHistoryRequestTop(this.params)
  }

  private onSubmit(): void {
    controller.createRequestTopup({
      ...this.form,
      customerId: Number(this.$route.params.id)
    })
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    if (!this.isSuperAdmin) {
      this.$router.push({name: 'RequestTopupPage'})
    }
    this.$router.replace({
      query: {...val},
    }).catch(() => false)
  }

  @Watch('controller.statusRequestTopupSaldo')
  onStatusRequestTopupSaldoChange(status: string): void {
    if (status !== '' && status === EventBusConstants.CREATE_REQUEST_TOPUP_SUCCESS) {
      this.modalConfirm = false
      this.modalSuccess = true
    }
  }

  beforeDestroy(): void {
    controller.setStatusRequestTopupSaldo('')
  }
}
