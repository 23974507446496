import { EnumTypeBalanceStatus } from '@/app/infrastructures/misc'
import {
  ApproveRejectBulkyRequestInterface,
  ApprovingTopupRequestInterface,
  RequestTopupRequestInterface,
} from '../contracts/AdjustmentRequest'

export class RequestTopupRequest implements RequestTopupRequestInterface {
  private readonly customerId: number
  private readonly amount: number
  private readonly note: string
  private readonly balanceTypeAdjustment: string

  constructor(customerId: number, amount: number, note: string, balanceTypeAdjustment: string) {
    this.customerId = customerId
    this.amount = amount
    this.note = note
    this.balanceTypeAdjustment = balanceTypeAdjustment
  }

  public toPayload(): string {
    const data = {
      customer_id: this.customerId,
      amount: this.amount,
      balance_type: this.balanceTypeAdjustment,
      note: this.note,
    }

    return JSON.stringify(data)
  }
}

export class ApprovingTopupRequest implements ApprovingTopupRequestInterface {
  private balanceId: number
  private balanceStatus: string
  private reasonNote: string

  constructor(balanceId: number, balanceStatus: string, reasonNote: string) {
    this.balanceId = balanceId
    this.balanceStatus = balanceStatus
    this.reasonNote = reasonNote
  }

  public toPayload(): string {
    const data = {
      balance_id: this.balanceId,
      balance_status: this.balanceStatus,
      reason_note: this.reasonNote
    }

    return JSON.stringify(data)
  }
}

export class ApproveRejectBulkyRequest implements ApproveRejectBulkyRequestInterface {
  private balanceStatus: EnumTypeBalanceStatus
  private balanceIDs: Array<number>
  private reasonNote?: string

  constructor(
    balanceStatus: EnumTypeBalanceStatus,
    balanceIDs: Array<number>,
    reasonNote?: string,
  ) {
    this.balanceStatus = balanceStatus
    this.balanceIDs = balanceIDs
    this.reasonNote = reasonNote
  }

  toPayload(): string {
    const data = {
      balance_status: this.balanceStatus,
      balance_ids: this.balanceIDs,
      reason_note: this.reasonNote,
    }

    return JSON.stringify(data)
  }
}
