








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  EnumTransactionParcelPoinRequest,
  Utils,
} from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    Modal,
    Button,
  },
})
export default class ModalConfirm extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: 'Title' }) private title!: string
  @Prop({ default: 'Sub Title' }) private subtitle!: string
  @Prop({ default: '' }) private phoneNumber!: string
  @Prop({ default: 0 }) private amount!: number
  @Prop({ default: '' }) private balanceTypeAdjustment!: string
  @Prop({ default: '' }) private note!: string
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: 'Yes, Submit' }) private textAction!: string
  @Prop({}) private transaction!: string
  @Prop({}) private poin!: number

  get phoneNumberUser(): string {
    if (this.phoneNumber) {
      return `(${Utils.countryIndonesiaPhoneNumber(this.phoneNumber, true)})`
    } else {
      return '-'
    }
  }

  get amountUser(): string {
    return Utils.toRupiah(this.amount)
  }

  get transactionUser(): string {
    return this.transaction.toUpperCase() ===
      EnumTransactionParcelPoinRequest.CREDIT
      ? 'Penambahan'
      : 'Pengurangan'
  }

  get poinUser(): string {
    return Utils.currencyDigit(this.poin).toString()
  }
}
