























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    Modal,
    Button,
  },
})
export default class ModalDetail extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: '' }) private phoneNumber!: string
  @Prop({ default: '' }) private notes!: string
  @Prop({ default: 'Notes' }) private title!: string

  get getTitle(): string {
    return this.title.replace(/_/g, ' ')
  }
}
