import { UploadRequestInterface } from '@/data/payload/contracts/UploadRequestInterface'

export class UploadRequest implements UploadRequestInterface {
  public file: File

  constructor(file: File) {
    this.file = file
  }

  public toPayload(): FormData {
    const form = new FormData()
    form.append('file', this.file)
    return form
  }
}
